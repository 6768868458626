import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { SectionWithObjects } from '../components/section'
import Container from '../components/container'
import Hero from '../components/hero'
import FAQ from '../components/faq'
import NextStep from '../components/next-step'
import getTranslations from '../utils/getTranslations'

const FAQPage = ({ data, pageContext }) => {
  const { locale } = pageContext
  const { page, heroBackgroundImage, nextStepBackground } = data
  const { title, content, acf, polylang_translations: translations } = page
  const {
    text_kam_dal: textKamDal,
    popisek_tlacitka_kam_dal: popisekTlacitkaKamDal,
    odkaz_kam_dal: odkazKamDal,
    dotazy,
  } = acf

  return (
    <Layout
      locale={locale}
      pageTranslationLinks={getTranslations(locale, translations)}
    >
      <SEO title={title} />

      <Hero
        title={title}
        html={content}
        backgroundImage={heroBackgroundImage}
        backgroundPosition="50% 30%"
      />

      <SectionWithObjects pt={5}>
        <Container>
          <FAQ items={dotazy} />
        </Container>
      </SectionWithObjects>

      <NextStep
        title={textKamDal || ''}
        link={odkazKamDal}
        label={popisekTlacitkaKamDal}
        backgroundImage={nextStepBackground}
      />
    </Layout>
  )
}

FAQPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default FAQPage

export const pageQuery = graphql`
  query FAQPageQuery($key: String!) {
    page: wordpressPage(slug: { eq: $key }) {
      ...PageData
      ...PageTranslations
      acf {
        dotazy {
          dotaz
          odpoved
          dokument_ke_stazeni {
            title
            url {
              localFile {
                publicURL
              }
            }
          }
        }
        ...NextStep
      }
    }
    heroBackgroundImage: file(relativePath: { eq: "caste-dotazy.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nextStepBackground: file(relativePath: { eq: "pivo-v-ruce.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
