import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { Box, Flex, Heading, Link } from 'rebass/styled-components'

import brushPlus from '../images/brush-plus.png'

const Plus = styled.img`
  width: 24px;
  height: 24px;
  margin: 0 1rem 0 0.5rem;
  opacity: 0.75;
  transition: all 200ms ease-in-out;
`

const Item = ({ question, answer, document }) => {
  const [opened, setOpened] = useState(false)

  return (
    <Box
      sx={{
        mb: 3,
        flexBasis: ['100%', '49%'],
      }}
    >
      <Heading
        as="h2"
        variant="faqTitle"
        sx={{
          display: 'flex',
          alignItems: 'center',
          borderRadius: opened ? '6px 6px 0 0' : '6px',
        }}
        onClick={() => setOpened(!opened)}
      >
        <Plus
          src={brushPlus}
          alt=""
          aria-hidden="true"
          style={{ transform: opened ? 'rotate(45deg)' : '' }}
        />

        {question}
      </Heading>

      <Box
        variant="faqAnswer"
        sx={{
          display: opened ? 'block' : 'none',
          borderRadius: opened ? '0 0 6px 6px' : '6px',
        }}
      >
        <Box
          mb={document ? 4 : 0}
          dangerouslySetInnerHTML={{ __html: answer }}
        />

        {document && (
          <Flex flexDirection="column" alignItems="flex-start">
            <Box sx={{ mb: 3, fontWeight: 'bold' }}>
              <FormattedMessage
                id="faq.forDownload"
                defaultMessage="Ke stažení"
              />
            </Box>

            {document.url.localFile && (
              <Link
                variant="primary"
                sx={{ textDecoration: 'none !important' }}
                href={document.url.localFile.publicURL}
              >
                {document.title}
              </Link>
            )}
          </Flex>
        )}
      </Box>
    </Box>
  )
}

const FAQ = ({ items }) => {
  return (
    <Flex
      sx={{
        flexWrap: 'wrap',
        justifyContent: ['space-between'],
      }}
    >
      {items &&
        items.map((item) => (
          <Item
            key={item.dotaz}
            question={item.dotaz}
            answer={item.odpoved}
            document={item.dokument_ke_stazeni}
          />
        ))}
    </Flex>
  )
}

FAQ.propTypes = {
  items: PropTypes.array.isRequired,
}

Item.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  document: PropTypes.object,
}

Item.defaultProps = {
  document: null,
}

export default FAQ
